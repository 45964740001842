import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Title } from '@/styles/global-styled-components';
import { X_URL_SOCIAL } from '@/constants';

const Community = () => (
  <div className="page-padding bg-gray-100 pt-24 lg:pt-32">
    <Title>Join the Volmex Community</Title>

    <div className="flex flex-wrap items-center mt-12">
      <div className="mr-20 mb-10">
        <StaticImage
          src="../../../assets/images/community/x.png"
          alt="Twitter"
          className="lg:w-[336px] lg:h-[216px]"
        />
        <div className="flex justify-between items-center mt-4">
          {/* <Subtitle>Twitter</Subtitle> */}
          <a
            href={X_URL_SOCIAL}
            target="_blank"
            className="text-gray-900 text-xl underline decoration-gray-900 underline-offset-4 decoration-1"
          >
            Follow
          </a>
        </div>
        <div className="text-gray-900 my-2">140,000+ followers</div>
      </div>

      <div className="mr-20 mb-10">
        <StaticImage
          src="../../../assets/images/community/discord.png"
          alt="Discord"
          className="lg:w-[336px] lg:h-[216px]"
        />
        <div className="flex justify-between items-center mt-4">
          {/* <Subtitle>Discord</Subtitle> */}
          <a
            href="https://discord.com/invite/volmex"
            target="_blank"
            className="text-gray-900 text-xl underline decoration-gray-900 underline-offset-4 decoration-1"
          >
            Join Discord
          </a>
        </div>
        <div className="text-gray-900 my-2">21,000+ members</div>
      </div>

      <div className="mr-20 mb-10">
        <StaticImage
          src="../../../assets/images/community/telegram.png"
          alt="Telegram"
          className="lg:w-[336px] lg:h-[216px]"
        />
        <div className="flex justify-between items-center mt-4">
          {/* <Subtitle>Telegram</Subtitle> */}
          <a
            href="https://t.me/VolmexUpdates"
            target="_blank"
            className="text-gray-900 text-xl underline decoration-gray-900 underline-offset-4 decoration-1"
          >
            Join Telegram
          </a>
        </div>
        <div className="text-gray-900 my-2">
          Regular market updates and news
        </div>
      </div>
    </div>
  </div>
);

export default Community;
