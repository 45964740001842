import React, { useState } from 'react';
import PythSVG from '@/assets/images/Pyth Logotype_Dark.svg';
import { DISCORD_URL_SOCIAL, X_URL_SOCIAL } from '@/constants';

const FAQ = () => {
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const faqData = [
    {
      question: `What is Volmex’s mission?`,
      answer: `Volmex Labs is the creator of the Volmex Implied Volatility Indices, the BVIV Index (Bitcoin Volmex Implied Volatility) and the EVIV Index (Ethereum Volmex Implied Volatility) - the first and leading crypto volatility indices. Volmex enables individuals and institutions to navigate the dynamic crypto landscape, make informed investment decisions, manage risks, and capitalize on market fluctuations to achieve their financial goal. We provide comprehensive volatility analysis, insights, and tools.`,
    },
    {
      question: `What is implied volatility?`,
      answer: `Volatility refers to the degree of variation or fluctuation in the price or value of a financial asset over a specific period of time. It is a statistical measure that indicates the level of uncertainty or risk associated with an asset's price movements.`,
    },
    {
      question: `What are the Volmex Implied Volatility Indices, the BVIV Index and EVIV Index?`,
      answer: `<p>The Volmex Implied Volatility Indices (e.g. BVIV Index, EVIV Index, etc) are designed 
        to measure the constant, 30-day expected volatility of the Bitcoin and Ethereum options market 
        (and other assets in the future), derived from real-time crypto call and put options. Use the 
        indices to track crypto volatility or trade linked derivatives. </p>
        &nbsp;
        <p>Volmex charts: <a class="underline" href="https://charts.volmex.finance/" rel="noopener noreferrer" target="_blank">https://charts.volmex.finance/</a></p> 
        <p>Volmex methodology technical paper: <a class="underline" href="https://volmex.finance/Volmex-IV-paper.pdf" rel="noopener noreferrer" target="_blank">https://volmex.finance/Volmex-IV-paper.pdf </a></p>`,
    },
    {
      question: `What are Volmex Perpetual Futures?`,
      answer: `
        <p> 
        Perpetual futures contracts allow users to get exposure to underlying indices, the BVIV and EVIV Index, 
        using USDC or USDT collateral and paying funding.
        </p>
        &nbsp;
        <p>
          Volmex perpetual futures whitepaper: 
          <a class="underline" href="https://volmex.finance/Perpetual-Futures.pdf" rel="noopener noreferrer" target="_blank">
            https://volmex.finance/Perpetual-Futures.pdf
          </a>
        </p>
        &nbsp;

        <p>
        Volmex BTC-USD and ETH-USD perpetual futures markets are powered by Pyth   
      <img src=${PythSVG} alt="Pyth" style="height: 24px; display: inline; padding-left: 6px;" />
      </p>
        `,
    },
    {
      question: `How can crypto investors, traders, and market participants use Volmex products and tools?`,
      answer: `
        <p>BVIV and EVIV perpetual futures can be used to manage risk. BVIV and EVIV tend to be very negatively correlated during times of panic in the crypto market, making it a great tool to hedge or diversify. Additionally, BVIV and EVIV perpetual futures can also be used to speculate on implied volatility levels to generate alpha.</p>
        &nbsp;
        <p>The BVIV and EVIV Indices are best-in-class volatility indices, which provide a reliable gauge for crypto market implied volatility. </p>
        `,
    },
    {
      question: `How can I start using the Volmex perpetual futures testnet?`,
      answer: `
        <p>
          The Volmex perpetual futures public testnet is live on Base and Arbitrum testnets.
        </p>
        <p>
        Get testnet tokens here: 
        <a class="underline" href="https://faucet.volmex.finance/" rel="noopener noreferrer" target="_blank">
        https://faucet.volmex.finance/
        </a>
        </p>
        <p>
        Access the user interface: 
        <a class="underline" href="https://perpetuals.volmex.finance/" rel="noopener noreferrer" target="_blank">
        https://perpetuals.volmex.finance/
        </a>
        </p>
        `,
    },
    {
      question: `How can I get more involved in the Volmex community?`,
      answer: `
        <p>
        Sign up for the email list: 
        <a class="underline" href="https://volmex.finance/" rel="noopener noreferrer" target="_blank">
        https://volmex.finance/
        </a>
        </p>
        <p>
        Follow Volmex on Twitter: 
        <a class="underline" href="${X_URL_SOCIAL}" rel="noopener noreferrer" target="_blank">
        ${X_URL_SOCIAL}
        </a>
        </p>
        <p>
        Join the Discord community: <a class="underline" href="${DISCORD_URL_SOCIAL}" rel="noopener noreferrer" target="_blank">
        ${DISCORD_URL_SOCIAL}
        </a>
        </p>
        `,
    },
    {
      question: `More about the Volmex Labs team`,
      answer: `The Volmex Labs team consists of world-class talent from various crypto and traditional financial industry companies including MSCI, IMC Trading, Staked, and more.`,
    },
  ];

  return (
    <div className="w-full bg-gray-100">
      <div className="bg-gray-100 text-black p-20 max-w-4xl m-auto">
        <h2 className="text-5xl pb-12">FAQ</h2>
        <ul>
          {faqData.map((item, index) => (
            <li key={item.question}>
              <button
                type="button"
                className="flex justify-between items-center cursor-pointer border-t-gray-500 border border-l-0 border-r-0 border-b-0 pt-8 w-full text-left"
                onClick={() => toggleItem(index)}
              >
                <h3 className="font-medium text-lg">{item.question}</h3>
                <svg
                  className={`w-6 h-6 transition-transform transform ${
                    expandedItems.includes(index) ? `rotate-180` : ``
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div className="p-3 pb-6">
                {expandedItems.includes(index) && (
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
